<template>
  <rpm-layout>
    <loading :loading="loading">
      <div class="grid md:grid-cols-1 lg:grid-cols-4 gap-3">
        <div class="grid col-span-1">
          <div>
            <patient-card :patient="patient" v-on:get-patient-details="getPatientDetails" />
          </div>
        </div>
        <div class="grid md:col-span-1 lg:col-span-3">
          <div>
            <div class="bg-white rounded-lg text-sm font-medium text-gray-500 text-center p-2">
              <div class="grid grid-cols-6">
                <div v-for="tab in tabs" :key="tab" v-on:click="toggleTab(tab)">
                  <div class="grid">
                    <button class="border-b-2 p-2" v-bind:class="{
                        'text-blue-600 border-blue-600 active': active_tab === tab,
                        'border-transparent hover:text-gray-600 hover:border-gray-300 ': active_tab !== tab,
                        }">
                      <v-badge :content="alerts[tab]" :value="alerts[tab]" color="red">
                        {{ tab }}
                      </v-badge>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <vitals v-if="active_tab === 'Vitals'" :patient="patient" :accessToken="accessToken" />
            <care-plans v-if="active_tab === 'Care Plans'" :patient="patient" :accessToken="accessToken" v-on:get-patient-details="getPatientDetails" ref="carePlansComponent" />
            <devices v-if="active_tab === 'Devices'" :patient="patient" :accessToken="accessToken" v-on:get-patient-details="getPatientDetails" ref="devicesComponent" />
            <tasks v-if="active_tab === 'Notes'" :is-notes="true" :patient="patient" ref="notesComponent" v-on:get-patient-details="getPatientDetails" />
            <tasks v-if="active_tab === 'Tasks'" :patient="patient" ref="tasksComponent" v-on:get-patient-details="getPatientDetails" />
            <alerts v-if="active_tab === 'Alerts'" :patient="patient"  v-on:get-patient-details="getPatientDetails" />
          </div>
        </div>
      </div>
    </loading>
  </rpm-layout>
</template>

<script>
import Vue from "vue";
import RpmLayout from "../../../layouts/RpmLayout.vue";
import Loading from "../../../components/Loading.vue";
import Alerts from "./components/Alerts.vue";
import PatientCard from "./components/PatientCard.vue";
import Vitals from "./components/Vitals.vue";
import CarePlans from "./components/CarePlans.vue";
import Devices from "./components/Devices.vue";
import Tasks from "./components/Tasks.vue";
import moment from "moment";

export default {
  components: {
    RpmLayout,
    Loading,
    PatientCard,
    Vitals,
    CarePlans,
    Devices,
    Tasks,
    Alerts,
  },
  data() {
    return {
      patient: null,
      loading: true,
      tabs: ["Vitals", "Care Plans", "Devices", "Notes", "Tasks", "Alerts"],
      active_tab: "Vitals",
      accessToken: null,
    };
  },
  computed: {
    alerts() {
      const today = moment().startOf('day');

      return {
        Vitals: 0,
        "Care Plans": 0,
        Devices: 0,
        "Notes": this.patient && this.patient.tasks ? this.patient.tasks.filter(t => t.status !== 'closed' && t.status !== 'open').length : 0,
        "Tasks": this.patient && this.patient.tasks ? this.patient.tasks.filter(t => {
          const taskDueDate = moment(t.due_date).startOf('day');
          return t.status === 'open' && t.due_date && taskDueDate.isSameOrBefore(today);
        }).length : 0,
        Alerts: this.patient && this.patient.vital_alerts_unresolved ? this.patient.vital_alerts_unresolved.length + (this.patient.vital_warnings_unresolved ? this.patient.vital_warnings_unresolved.length : 0) : 0,
      }
    },
  },
  methods: {
    async getAccessToken() {
      this.accessToken = await this.$auth.getTokenSilently();
    },
    getPatientDetails() {
      return Vue.$http
        .get("/api/rpm_ccm_dashboard/patient_details", {
          params: {
            patient_id: this.$route.params.id,
            token: this.accessToken,
          },
        })
        .then((res) => (this.patient = res.data.data))
        .catch((error) => this.handleEhrApiErrors(error, this.errors))
        .finally(() => {
          this.loading = false;
        });
    },
    toggleTab(tab) {
      this.active_tab = tab;
    },
  },
  created: async function () {
    await this.getAccessToken();
    Promise.all([
      this.getPatientDetails(),
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser') : null,
      !this.$store.state.patients.providers.length ? this.$store.dispatch('getProviders') : null,
      !this.$store.state.patients.templates.length ? this.$store.dispatch('getTemplates') : null,
    ]).finally(() => {
      this.loading = false;
    });
  },
  watch: {
    $route: "getPatientDetails",
  },
};
</script>

<style scoped>
.active_tab {
  color: #5e60ce;
  border-color: #5e60ce;
}

.inactive_tab:hover {
  border-color: transparent;
}
</style>
